<template>
  <!-- eslint-disable -->
   <div>
       <sw-top-page-bar
               v-model="activeTab"
               is-tab-view
               :tab-view-list="tabList"
       >
           <template v-if="activeTab">
               <div
                       v-if="activeTab.title === tabList[0].title"
                       :key="0"
               >
                   <sw-filters
                           :shorts="[
                  {
                    title: $t('task.Status'),
                    value: () => filters.status === 1 || filters.status === 0 ? $t(`offer.product.${filters.status === 1 ? 'active' : 'inactive'}`) : null,
                    clear: () => { filters.status = null; loadData() }
                  },
              ]"
                           :res="[
                {
                  title: $t('All'),
                  value: () => pagination.totalRecords,
                }
              ]"
                   >
                       <b-row class="client_filters">
                           <b-col cols="12" md="4">
                               <!--  Table Filters - Users - status -->
                               <sw-select :name="$t('task.Status')">
                                   <v-select @input="loadData" v-model="filters.status" :reduce="r => r.value" :options="[{ label: 'active', value: 1 }, { label: 'inactive', value: 0 }]">
                                       <template #no-options>
                                           {{ $t('NoOptions') }}
                                       </template>
                                       <template #option="{ label }">
                                           {{ $t(`offer.product.${label}`) }}
                                       </template>
                                       <template #selected-option="{ label }">
                                           {{ $t(`offer.product.${label}`) }}
                                       </template>
                                   </v-select>
                               </sw-select>
                               <!--  Table Filters - Users - status -->
                           </b-col>
                       </b-row>
                   </sw-filters>

                   <div class="my-50">
                       <b-button
                               variant="primary"
                               size="sm"
                               class="mr-50"
                               @click="sendPushNotificationModal = { show: true, item: { message: '', title: '', language: '', platform: 'ANDROID', clients: selectedContacts.length ? selectedContacts : 'ALL' } }"
                       >
                           <sw-icon icon="BellIcon" />
                           {{ $t('SendPushNotification') }}
                       </b-button>
                   </div>

                   <b-card>
                       <sw-table
                               table-id="f43d45af8e5e4c968457c5e8a792ceb8"
                               show-columns
                               :fields.async="clientFields"
                               :pagination="pagination"
                               @change-pagination="Object.assign(pagination, $event)"
                               @reload-content="loadData"
                               @set-fields="clientFields = $event"
                       >
                           <template #table>
                               <b-table
                                       ref="selectableTable"
                                       :items="items"
                                       class="table-wrap-words mx-0 px-0"
                                       :fields="fields"
                                       striped
                                       responsive
                                       :busy="loading"
                                       show-empty
                                       :sort-by.sync="sorter.sortBy"
                                       :sort-desc.sync="sorter.sortDesc"
                                       :no-local-sorting="true"
                                       @sort-changed="Object.assign(sorter, $event); loadData()"
                               >
                                   <template #head()="{ label }">
                                       {{ $t(`${label}`) }}
                                   </template>
                                   <template #cell(id)="{ index }">
                                       #{{ getOrderNo(index, pagination, sorter) }}
                                   </template>
                                   <template #cell(name)="{ item }">
                                       <avatar show-name class="py-25" :user="item" />
                                   </template>

                                   <template #cell(type)="{ value }">
                                       {{ $t('Person') }}
                                   </template>

                                   <template #head(select)="{ label }">
                                       <b-form-checkbox v-model="selectAll"
                                                        @change="selectAllContacts"
                                       />
                                   </template>
                                   <template #cell(select)="{ item }">
                                       <b-form-checkbox v-model="selectedContacts" :value="item.id" />
                                   </template>

                                   <template #cell(canLogin)="{ value, item, index }">
                                       <b-form-checkbox variant="primary" :disabled="!checkRequiredPermissions([$roles.CLIENT_EDIT_ALL])" switch :checked="value" @change="changeCanLoginStatus(item.id, !value, index)">
                              <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                                           <span class="switch-icon-right">
                              <feather-icon icon="XIcon" />
                            </span>
                                       </b-form-checkbox>
                                   </template>

                                   <template #cell(platform)="{ item, index }">
                                       <div v-if="item.clientDevices && item.clientDevices.length">
                                           <b-badge :key="`device_${index}_${deviceIndex}`" v-for="(device, deviceIndex) in item.clientDevices" variant="light-primary">
                                               <div  class="d-flex align-items-center justify-content-center">
                                                   <sw-icon v-if="device.platform === 'ios'" icon="io-logo-apple" />
                                                   <sw-icon v-else-if="device.platform === 'android'" icon="io-logo-android" />
                                                   <sw-icon v-else icon="io-globe-outline" />

                                                   <span class="ml-25" :class="{ 'text-uppercase': device.platform === 'ios', 'text-capitalize': device.platform !== 'ios' }">{{ device.platform }}</span>
                                               </div>
                                           </b-badge>
                                       </div>
                                   </template>

                                   <template #cell(ai)="{ item, index }">
                                       <b-form-checkbox variant="primary" :disabled="!checkRequiredPermissions([$roles.CLIENT_EDIT_ALL])" switch :checked="item.aiEnabled" @change="changeAiStatus(item.id, item.chatId, !item.aiEnabled, index)">
                              <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                                           <span class="switch-icon-right">
                              <feather-icon icon="XIcon" />
                            </span>
                                       </b-form-checkbox>
                                   </template>

                                   <template #cell(chats)="row">
                                       <!--        <documents-list :documents="item.contactThreadAgreements.map(item => item.filePdf)" />-->
                                       <b-button
                                               size="sm"
                                               variant="primary"
                                               class="d-flex align-items-center px-50 py-25"
                                               @click="() => { loadClientChats(row.item.id, row.index); row.toggleDetails() }"
                                       >
                                           <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'" />
                                       </b-button>
                                   </template>
                                   <template #row-details="{ item, index }">
                                       <div v-if="item.loading" class="d-flex justify-content-center p-1">
                                           <b-spinner variant="primary" />
                                       </div>
                                       <div v-else>
                                           <b-list-group v-if="item.chats && item.chats.length">
                                               <b-list-group-item class="d-flex flex-column" v-for="(chat, chatIndex) in item.chats" :key="`client_${index}_chat_${chatIndex}`">
                                                   <div class="mb-50">
                                                       <span v-if="chat.clientChatMessageLast && chat.clientChatMessageLast.senderUser">{{ $t('You') }}: </span>
                                                       <span v-if="chat.clientChatMessageLast">
                              <span v-if="chat.clientChatMessageLast.message">
                                {{ (chat.clientChatMessageLast.message || '-') | truncate(50, '…') }}
                              </span>
                              <span v-else-if="chat.clientChatMessageLast.type">
                                <feather-icon icon="PaperclipIcon" />
                                {{ $t(`${String(chat.clientChatMessageLast.type).ucFirst()}`) }}
                              </span>
                              <feather-icon v-else icon="MinusIcon" />
                            </span>
                                                   </div>

                                                   <div class="d-flex align-items-center">
                                                       <div class="mr-1">
                                                           <feather-icon
                                                                   size="22"
                                                                   :class="{ 'active': chat.archived }"
                                                                   class="hover-primary cursor-pointer"
                                                                   icon="ArchiveIcon"
                                                                   @click="updateField(chat, 'archived', !chat.archived, index, chatIndex)"
                                                           />
                                                       </div>
                                                       <div class="mr-1">
                                                           <feather-icon
                                                                   size="22"
                                                                   :class="{ 'active': chat.favorite }"
                                                                   class="hover-warning cursor-pointer"
                                                                   icon="StarIcon"
                                                                   @click="updateField(chat, 'favorite', !chat.favorite, index, chatIndex)"
                                                           />
                                                       </div>

                                                       <b-form-checkbox
                                                               v-model="chat.aiEnabled"
                                                               v-b-tooltip
                                                               switch
                                                               variant="primary"
                                                               :title="$t('TurnOnAiAnswer')"
                                                               @change="updateConversaitonAi(chat, $event, index, chatIndex)"
                                                       >
                              <span class="switch-icon-left">
                                <feather-icon icon="CpuIcon" />
                              </span>
                                                           <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                              </span>
                                                       </b-form-checkbox>

                                                       <div class="cursor-pointer d-flex align-items-center mr-25" :id="`convseration_ai_${index}_${chatIndex}`" v-if="chat.aiEnabled">
                                                           {{ chat.autoReplyAiType ? $t(chat.autoReplyAiType) : $t('SelectAI') }}
                                                           <feather-icon icon="ChevronDownIcon" size="18" />

                                                           <b-popover :target="`convseration_ai_${index}_${chatIndex}`" triggers="hover focus click">
                                                               <div @click="updateConversationAiType(chat, 'OPENAI_ASSISTANT', index, chatIndex, true)" class="w-100 rounded p-50 cursor-pointer" :class="{ 'bg-primary text-light': chat.autoReplyAiType === 'SALES_AI' }">
                                                                   <feather-icon :icon="chat.autoReplyAiType === 'OPENAI_ASSISTANT' ? 'CpuIcon' : 'XIcon'" />
                                                                   {{ $t('OPENAI_ASSISTANT') }}</div>
                                                               <div v-if="isLasland" @click="updateConversationAiType(chat, 'QUICK_CHAT', index, chatIndex, true)" class="w-100 rounded p-50 cursor-pointer" :class="{ 'bg-primary text-light': chat.autoReplyAiType === 'QUICK_CHAT' }">
                                                                   <feather-icon :icon="chat.autoReplyAiType === 'QUICK_CHAT' ? 'CpuIcon' : 'XIcon'" />
                                                                   {{ $t('QUICK_CHAT') }}</div>
                                                           </b-popover>
                                                       </div>
                                                   </div>

                                               </b-list-group-item>
                                           </b-list-group>
                                           <div
                                                   v-else
                                                   class="p-1 text-center text-primary mt-1 mb-n50"
                                           >
                                               <feather-icon icon="XCircleIcon" />
                                               {{ $t('NoData') }}
                                           </div>
                                       </div>
                                   </template>

                                   <template #cell(activated)="{ value }">
                                       <b-badge :variant="!value ? 'light-danger' : 'light-primary'">
                                           {{ $t(!value ? 'offer.product.inactive' : 'offer.product.active') }}
                                       </b-badge>
                                   </template>
                                   <!--    Other    -->
                                   <template #cell()="row">
                                       <table-default-cell
                                               :field="row.field"
                                               :value="row.value"
                                       />
                                   </template>

                                   <!--      Empty      -->
                                   <template #empty>
                                       <empty-content />
                                   </template>

                                   <!--      Table Busy      -->
                                   <template #table-busy>
                                       <div class="text-center text-danger my-2">
                                           <b-spinner
                                                   class="align-middle"
                                                   variant="primary"
                                           />
                                       </div>
                                   </template>
                               </b-table>
                           </template>
                       </sw-table>
                   </b-card>
               </div>

               <!-- History Table -->
               <div
                       v-if="activeTab.title === tabList[1].title"
                       :key="1"
               >
                   <div class="my-50">
                       <b-button
                               variant="primary"
                               size="sm"
                               class="mr-50"
                               @click="sendPushNotificationModal = { show: true, item: { message: '', title: '', language: '', platform: 'ANDROID', clients: selectedContacts.length ? selectedContacts : 'ALL' } }"
                       >
                           <sw-icon icon="BellIcon" />
                           {{ $t('SendPushNotification') }}
                       </b-button>
                   </div>
                   <b-card>
                       <!-- eslint-disable -->
                       <sw-table
                               table-id="01c7c2e92c6e49d5841f04bfeaa54358"
                               :pagination="historyPagination"
                               @change-pagination="Object.assign(historyPagination, $event)"
                               @reload-content="loadHistory"
                       >
                           <template #table>
                               <b-table
                                       :items="historyItems"
                                       :fields="tableFields"
                                       striped
                                       responsive
                                       :busy="historyLoading"
                                       show-empty
                               >
                                   <template #cell(content)="row">
                                       <span v-b-tooltip :title="row.value" :inner-html.prop="row.value | truncate(20, '...')" />
                                   </template>
                                   <template #head()="item">
                                       <div
                                               class="d-flex flex-row align-items-center"
                                               style="column-gap: .34rem"
                                       >
                                           {{ $t(item.label) }}

                                           <feather-icon
                                                   v-if="item.field.tooltip"
                                                   v-b-tooltip.hover.v-primary
                                                   :title="item.field.tooltip ? $t(item.field.tooltip) : ''"
                                                   icon="InfoIcon"
                                           />
                                       </div>
                                   </template>

                                   <template #cell(message)="row">
                                       <div v-b-tooltip :title="row.value">
                                           <sw-icon icon="EyeIcon" class="text-primary mr-25"></sw-icon>
                                           <span :inner-html.prop="row.value | truncate(30, '...')" />
                                       </div>
                                   </template>

                                   <template #cell(platform)="{ value }">
                                       <div v-if="value" class="text-primary">
                                           <sw-icon v-if="value === 'ios'" icon="io-logo-apple" />
                                           <sw-icon v-else-if="value === 'android'" icon="io-logo-android" />
                                           <sw-icon class="text-primary" v-else-if="value" icon="io-globe-outline" />

                                           <span class="ml-25" :class="{ 'text-uppercase': value === 'ios', 'text-capitalize': platform !== 'ios' }">{{ (value || '-').toLowerCase() }}</span>
                                       </div>
                                       <sw-icon icon="MinusIcon" v-else></sw-icon>
                                   </template>

                                   <!--    Other    -->
                                   <!--    Other    -->
                                   <template #cell()="row">
                                       <table-default-cell
                                               :field="row.field"
                                               :value="row.value"
                                       />
                                   </template>

                                   <!--      Empty      -->
                                   <template #empty>
                                       <empty-content />
                                   </template>

                                   <!--      Table Busy      -->
                                   <template #table-busy>
                                       <div class="text-center text-danger my-2">
                                           <b-spinner
                                                   class="align-middle"
                                                   variant="primary"
                                           />
                                       </div>
                                   </template>
                               </b-table>
                           </template>
                       </sw-table>
                   </b-card>
               </div>
           </template>
       </sw-top-page-bar>

       <div>
           <b-modal
                   v-model="sendPushNotificationModal.show"
                   :title="$t('SendPushNotification')"
                   hide-footer
                   modal-class="pr-0"
                   @hide="sendPushNotificationModal = { show: false, item: { message: '', title: '', language: '', platform: 'ANDROID', clients: [] } }"
           >
               <div>
                   <!--              <b-alert show variant="primary">-->
                   <!--                  <div class="alert-body">-->
                   <!--                      {{ $t('SendPushNotificationInfoBox') }}-->
                   <!--                  </div>-->
                   <!--              </b-alert>-->
                   <b-form-group :label="$t('Title')" class="form-label-group">
                       <b-form-input
                               v-model="sendPushNotificationModal.item.title"
                       />
                   </b-form-group>

                   <b-form-group :label="$t('Content')" class="form-label-group">
                       <b-form-textarea
                               id="report-content"
                               v-model="sendPushNotificationModal.item.message"
                               rows="5"
                               no-resize
                       />
                   </b-form-group>

                   <sw-select :name="$t('Language')">
                       <v-select
                               v-model="sendPushNotificationModal.item.language"
                               :options="filteredLocales"
                               :reduce="e => e.value"
                       >
                           <template #option="{ label }">
                               {{ $t(label) }}
                           </template>
                           <template #selected-option="{ label }">
                               {{ $t(label) }}
                           </template>
                       </v-select>
                   </sw-select>

                   <!--              <div class="mb-50 cursor-pointer border rounded p-1" @click="sendPushNotificationModal.item.platform ='ALL' " :class="{ 'border-primary': sendPushNotificationModal.item.platform === 'ALL' }">-->
                   <!--                  <sw-icon icon="io-globe-outline" />-->
                   <!--                  {{ $t('All').toUpperCase() }}-->
                   <!--              </div>-->
                   <!--              <div class="mb-50 cursor-pointer border rounded p-1" @click="sendPushNotificationModal.item.platform ='IOS' " :class="{ 'border-primary': sendPushNotificationModal.item.platform === 'IOS' }">-->
                   <!--                  <sw-icon icon="io-logo-apple" />-->
                   <!--                  IOS-->
                   <!--              </div>-->
                   <div class="mb-50 cursor-pointer border rounded p-1" @click="sendPushNotificationModal.item.platform ='ANDROID' " :class="{ 'border-primary': sendPushNotificationModal.item.platform === 'ANDROID' }">
                       <sw-icon icon="io-logo-android" />
                       ANDROID
                   </div>

                   <!--  Footer  -->
                   <div class="d-flex justify-message-end pt-1 mt-1 border-top">
                       <b-button
                               class="ml-25"
                               size="sm"
                               variant="primary"
                               :disabled="!sendPushNotificationModal.item.message"
                               @click="sendPushNotification"
                       >
                           {{ $t('Send') }}
                       </b-button>
                   </div>
               </div>
           </b-modal>
       </div>
   </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import {
  BFormTextarea, BPopover, BTable, VBTooltip,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import vSelect from 'vue-select'
import * as fields from '@/@constants/fields'

export default {
  name: 'MobileClients',
  components: {
    BFormTextarea,
    BPopover,
    vSelect,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: vm => ({
    historyLoading: false,
    historyPagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },

    historyItems: [],
    tableFields: [
      {
        key: 'title', label: 'Title',
      },
      {
        key: 'message', label: 'Content',
      },
      { key: 'platform', label: 'Platform' },
      { key: 'language', label: 'Language', translate: true },
      {
        key: 'recipients',
        label: 'Recipients',
        tag: 'b-badge',
        props: { variant: 'light-primary' },
      },
      { key: 'createdAt', label: 'CreatedAt' },
      {
        key: 'createdBy', label: 'CreatedBy', tag: 'avatar',
      },
    ],

    activeTab: null,
    tabList: [
      {
        title: 'Clients',
        icon: 'UsersIcon',
      },
      {
        title: 'History',
        icon: 'ClockIcon',
      },
    ],

    selectedContacts: [],
    selectAll: false,
    loading: false,
    search: '',
    items: [],
    filters: {
      status: null,
      type: null,
    },
    sendPushNotificationModal: {
      show: false,
      item: {
        message: '',
        title: '',
        language: '',
        platform: 'ANDROID',
        clients: [],
      },
    },
    clientFields: [
      {
        key: 'select', label: 'Select', visible: true,
      },
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'name', label: 'Name', visible: true },
      { key: 'email', label: 'Email', visible: true },
      { key: 'platform', label: 'Platform', visible: true },
      { key: 'type', label: 'task.Type', visible: true },
      { key: 'ai', label: 'AI', visible: true },
      {
        key: 'chats',
        label: 'Chats',
        visible: true,
        requiredPermissions: [
          vm.$roles.CLIENT_CHAT_MOBILE_VIEW_ALL,
          vm.$roles.CLIENT_CHAT_WHATSAPP_VIEW_ALL,
          vm.$roles.CLIENT_CHAT_MESSENGER_VIEW_ALL,
          vm.$roles.CLIENT_CHAT_MOBILE_VIEW_ASSIGNED_CONTACT_THREAD,
          vm.$roles.CLIENT_CHAT_WHATSAPP_VIEW_ASSIGNED_CONTACT_THREAD,
          vm.$roles.CLIENT_CHAT_MESSENGER_VIEW_ASSIGNED_CONTACT_THREAD,
        ],
      },
      { key: 'activated', label: 'Status', visible: true },
      { key: 'canLogin', label: 'LoginAccess', visible: true },
      { key: 'createdAt', label: 'contact.lastLoginAt', visible: true },
    ],
    globalSettings: null,
    pagination: {
      perPage: 50,
      currentPage: 1,
      totalRecords: 0,
    },
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    locales: [
      { value: 'pl', label: 'pl_pl' },
      { value: 'en', label: 'en_uk' },
      { value: 'de', label: 'de_de' },
      { value: 'ar', label: 'ar_dz', requiredDomain: 'lasland' },
      { value: 'ko', label: 'ko_kr', requiredDomain: 'lasland' },
      { value: 'zh', label: 'zh_cn', requiredDomain: 'lasland' },
    ],
  }),
  computed: {
    filteredLocales() {
      return this.locales.filter(locale => !locale.requiredDomain || window.location.href.includes(locale.requiredDomain))
    },
    isLasland() {
      return window.location.origin.includes('lasland')
    },
    fields() {
      return this.clientFields.filter(field => field.visible).filter(tab => !tab?.requiredPermissions || this.checkRequiredPermissions(tab.requiredPermissions))
    },
  },
  watch: {
    selectedContacts: {
      handler() {
        this.selectAll = this.selectedContacts.length === this.items.length
      },
      deep: true,
    },
  },
  async mounted() {
    await Promise.all([
      this.loadData(),
      this.loadHistory(),
      this.loadGlobalSettings(),
    ])
  },
  methods: {
    selectAllContacts() {
      this.selectedContacts = this.selectAll ? this.items.map(contact => contact.id) : []
    },
    formatDate,
    async updateConversaitonAi(conversation, value, userIndex, conversationIndex) {
      try {
        const resp = await axiosIns.patch('1/clientChats', { aiEnabled: value, id: conversation.id })

        if (value && !conversation.autoReplyAiType) {
          await this.updateConversationAiType(conversation, this.globalSettings?.clientChatDefaultAiType || 'OPENAI_ASSISTANT', userIndex, conversationIndex)
        }

        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        return resp.data?.data?.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return (err)
      }
    },
    async updateConversationAiType(conversation, value, userIndex, conversationIndex, showToast = false) {
      try {
        const resp = await axiosIns.patch('1/clientChats', { autoReplyAiType: value, id: conversation.id })
        if (showToast) this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.$set(this.items[userIndex].chats[conversationIndex], 'autoReplyAiType', value)

        return resp.data?.data?.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return (err)
      }
    },
    async updateField({ id }, field, value, userIndex, chatIndex) {
      try {
        const resp = await axiosIns.patch('1/clientChats', { [field]: value, id })
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.$set(this.items[userIndex].chats[chatIndex], field, value)

        return resp
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      }
    },
    async loadGlobalSettings() {
      try {
        const { data: response } = await axiosIns.get('1/settings/global', { params: { fields_load: fields.SETTINGS_GLOBAL } })
        this.globalSettings = response.data.item

        return response.data.item || null
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

        return err
      }
    },
    async changeCanLoginStatus(id, value, index) {
      try {
        const resp = await axiosIns.patch('1/clients', { id, canLogin: value })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.$set(this.items[index], 'canLogin', value)

        return resp
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      }
    },
    async changeAiStatus(id, chatId = null, value, index) {
      try {
        const resp = chatId
          ? await axiosIns.patch('1/clientChats', { aiEnabled: value, id: chatId })
          : await axiosIns.patch('1/clients', { aiEnabled: value, id })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.$set(this.items[index], 'aiEnabled', value)

        return resp
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      }
    },
    async loadClientChats(clientId, index) {
      this.$set(this.items[index], 'loading', true)

      const params = {
        fields_load: 'CLIENT_CHAT_USERS',
        'eqArr_chatClient-id': clientId,
      }

      try {
        const resp = await axiosIns.get('1/clientChats', { params })
        const chats = resp.data?.data?.items || []

        this.$set(this.items[index], 'chats', chats)

        return chats
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return (err)
      } finally {
        setTimeout(() => {
          this.$set(this.items[index], 'loading', false)
        }, 500)
      }
    },
    async sendPushNotification() {
      try {
        const payload = {
          ...this.sendPushNotificationModal.item,
        }

        delete payload.users
        delete payload.clients

        const response = await axiosIns.post('1/pushNotifications', payload)

        this.showToast('success', this.$i18n.t('MessageSendSuccessfully'))

        this.loadHistory()
        return response
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return null
      } finally {
        this.sendPushNotificationModal = { show: false, item: { message: '', clients: [] } }
      }
    },
    async loadData() {
      this.loading = true
      try {
        const params = {
          fields_load: this.$fields.MOBILE_FULL_USER,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
        }

        if (this.filters.status !== null && this.filters.status !== '') {
          params.eq_activated = this.filters.status
        }

        // if (this.filters.type) {
        //   params.eq_type = this.filters.type
        // }

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        if (this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = ''
        }

        const resp = await axiosIns.get('1/clients', { params })

        if (resp.data.data.items) {
          this.items = (resp.data.data.items || []).map(client => ({
            ...client, chatId: null, loading: false, chats: [],
          }))
          this.pagination.totalRecords = resp.data.data.totalItemCount
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async loadHistory() {
      this.historyLoading = true
      try {
        const params = {
          fields_load: 'PUSH_NOTIFICATIONS',
          page: this.historyPagination.currentPage,
          limit: this.historyPagination.perPage,
          orderBy: 'id.desc',
        }

        if (this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = ''
        }

        const resp = await axiosIns.get('1/pushNotifications', { params })

        if (resp.data.data.items) {
          this.historyItems = (resp.data.data.items || []).map(e => ({
            ...e,
            language: this.filteredLocales.find(a => a.value === e.language)?.label || null,
          }))
          this.historyPagination.totalRecords = resp.data.data.totalItemCount
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.historyLoading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
